<template>
  <div>
    <div class="title">自定义审批表单</div>

    <div style="position: relative">
      <div class="save_button custom_button no_select" @click="onSubmit">
        保存
      </div>

      <el-tabs
        style="margin-top: 20px"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="表单属性" name="表单属性">
          <el-form
            ref="form"
            :rules="rules"
            label-position="top"
            :model="form"
            label-width="80px"
          >
            <el-form-item label="图标" prop="cover_img">
              <el-image
                v-if="form.cover_img"
                :src="form.cover_img"
                style="width: 100px; height: 100px"
                fit="cover"
                @click="chooseImage"
              ></el-image>
              <div v-else class="add_photo" @click="chooseImage">
                <i class="el-icon-plus"></i>
              </div>
              <input
                ref="fileInput"
                type="file"
                accept="image/*"
                @change="uploadImage"
                @click="
                  (event) => {
                    event.target.value = null;
                  }
                "
                style="width: 0; height: 0; position: absolute"
              />
            </el-form-item>

            <el-form-item label="审批名称" prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>

            <el-form-item label="审批分组" prop="cate_id">
              <el-select v-model="form.cate_id" placeholder="请选择">
                <el-option
                  v-for="item in cate_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="审批排序" prop="sort_num">
              <el-input v-model="form.sort_num" type="number"></el-input>
            </el-form-item>

            <el-form-item label="审批说明" prop="desc">
              <el-input v-model="form.desc" type="textarea"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="自定义内容" name="自定义内容">
          <div class="phone_box">
            <el-image
              :src="require('@/assets/bgs/phone.png')"
              fit="contain"
              style="width: 800px; height: 800px"
            ></el-image>
            <div class="phone_screen">
              <!-- .auditformFlowList -->
              <div
                :class="{ active: selectedItem == index }"
                v-for="(item, index) in form_items"
                :key="index"
                @click="onItemSelected(index)"
              >
                <div class="input no_select" v-if="item.type == 1">
                  <div>{{ item.title }}</div>
                  <div
                    :class="{
                      default: item.default_val !== '',
                    }"
                  >
                    {{ item.default_val === "" ? item.tips : item.default_val }}
                  </div>
                </div>
                <div class="input no_select" v-if="item.type == 2">
                  <div>{{ item.title }}</div>
                  <textarea
                    cols="10"
                    rows="10"
                    :placeholder="item.tips"
                    :value="item.default_val"
                  ></textarea>
                </div>
                <div class="input no_select" v-if="item.type == 10">
                  <div>{{ item.title }}</div>
                  <div
                    :class="{
                      default: item.default_val !== '',
                    }"
                  >
                    {{ item.default_val === "" ? item.tips : item.default_val }}
                  </div>
                </div>
                <div
                  class="input no_select"
                  v-if="item.type == 3 || item.type == 4"
                >
                  <div>{{ item.title }}</div>
                  <div v-if="item.default_val.length == 0">
                    {{ item.tips }}
                  </div>
                  <div v-else-if="Array.isArray(item.default_val)">
                    {{ item.default_val.map((arr) => item.options[arr - 1]) }}
                  </div>
                  <div v-else>
                    {{ item.options[item.default_val - 1] }}
                  </div>
                  <i class="el-icon-arrow-right"></i>
                </div>
                <div
                  class="no_select file"
                  v-if="item.type == 5 || item.type == 6"
                >
                  <div class="file_title">
                    <div>{{ item.title }}</div>
                    <div>{{ item.tips }}</div>
                  </div>
                  <div class="file_items">
                    <div class="add_photo" style="width: 50px; height: 50px">
                      <i class="el-icon-plus"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="add_item no_select" @click="addFormItem">
                <div>添加字段</div>
              </div>
            </div>
          </div>

          <!-- 表单侧窗 -->
          <el-drawer
            title="自定义表单字段"
            :visible.sync="dialog"
            direction="rtl"
            ref="drawer"
            :modal="false"
            @closed="drawerClose"
          >
            <div class="drawer_content">
              <el-form
                v-if="selectedItem !== null"
                :model="form_items[selectedItem]"
              >
                <el-form-item label="字段类型" prop="type">
                  <el-select
                    v-model="form_items[selectedItem].type"
                    placeholder="请选择活动区域"
                  >
                    <el-option
                      v-for="item in formItemList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="字段名称" prop="title">
                  <el-input
                    v-model="form_items[selectedItem].title"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="输入提示" prop="tips">
                  <el-input
                    v-model="form_items[selectedItem].tips"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="上传文件类型"
                  prop="tips"
                  v-if="
                    form_items[selectedItem].type == 5 ||
                    form_items[selectedItem].type == 6
                  "
                >
                  <el-select
                    v-model="form_items[selectedItem].params.type"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in mideaOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <template
                  v-if="
                    form_items[selectedItem].type == 1 ||
                    form_items[selectedItem].type == 2
                  "
                >
                  <el-form-item label="默认值" prop="default_val">
                    <el-input
                      v-model="form_items[selectedItem].default_val"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                </template>
                <template
                  v-if="
                    form_items[selectedItem].type == 3 ||
                    form_items[selectedItem].type == 4
                  "
                >
                  <el-divider></el-divider>
                  <el-form-item
                    :label="'选项' + (index + 1)"
                    prop="options"
                    v-for="(item, index) in form_items[selectedItem].options"
                    :key="index"
                  >
                    <div class="pickerInput">
                      <el-input
                        v-model="form_items[selectedItem].options[index]"
                        autocomplete="off"
                      ></el-input>
                      <i
                        class="el-icon-remove"
                        v-if="index != 0"
                        @click="removeOption(form_items[selectedItem], index)"
                      ></i>
                      <i
                        class="el-icon-circle-plus"
                        v-if="
                          form_items[selectedItem].options.length - 1 == index
                        "
                        :style="index == 0 && 'margin: 0 5px;'"
                        @click="addOption(form_items[selectedItem])"
                      ></i>
                    </div>
                  </el-form-item>
                  <el-divider></el-divider>
                  <el-form-item
                    label="默认值"
                    prop="default_val"
                    v-if="
                      form_items[selectedItem].type != 5 &&
                      form_items[selectedItem].type != 6
                    "
                  >
                    <el-select
                      v-model="form_items[selectedItem].default_val"
                      :multiple="form_items[selectedItem].type == 4"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in form_items[selectedItem]
                          .options"
                        :key="index + 1"
                        :label="item"
                        :value="index + 1"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
                <div class="custom_button no_select" @click="removeFormItem">
                  删除字段
                </div>
              </el-form>
            </div>
          </el-drawer>
        </el-tab-pane>
        <el-tab-pane label="自定义流程" name="自定义流程">
          <el-form
            ref="form2"
            :rules="rules2"
            label-position="top"
            :model="form2"
            label-width="80px"
          >
            <el-form-item label="发起人权限" prop="apply_power">
              <el-radio-group v-model="form2.apply_power">
                <el-radio label="1">所有员工可申请</el-radio>
                <el-radio label="2">指定员工可申请</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item
              label="指定员工"
              prop="ids"
              v-if="form2.apply_power == '2'"
            >
              <el-select
                v-model="form2.ids"
                multiple
                filterable
                reserve-keyword
                placeholder="请输入员工姓名"
                autocomplete="off"
              >
                <el-option
                  v-for="item in allEmployeeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="approve_title">
            默认审批人<span>当未设置时，发起人可自行选择审批人</span>
          </div>
          <el-timeline class="approvers_boxs">
            <el-timeline-item
              v-for="(approver, index) in approvers"
              :key="index"
              hide-timestamp
              color="#4db9d5"
            >
              <div class="approvers_box" @click="onApproverSelected(index)">
                <div class="approvers_box_title">
                  {{ approver.name }}
                  <span v-if="approver.type == 1">（依次审批）</span>
                  <span v-if="approver.type == 2">（会签审批）</span>
                  <span v-if="approver.type == 3">（或签审批）</span>
                </div>
                <div class="approvers_box_users">
                  <div
                    v-for="user in approver.users"
                    :key="user"
                    :class="{ order: approver.type == 1 }"
                  >
                    {{ getEmployee(user) && getEmployee(user).name }}
                  </div>
                </div>
              </div>
            </el-timeline-item>
            <el-timeline-item
              v-if="
                approvers.length == 0 ||
                approvers[approvers.length - 1].users.length > 0
              "
            >
              <div
                class="add_approver_button custom_button plain no_select"
                @click="addApprover"
              >
                点击此处添加一轮审批
              </div>
            </el-timeline-item>
          </el-timeline>
          <div class="approvers_boxs">
            <div class="approve_title">
              默认抄送人<span
                >根据抄送通知设置，审批发起时或审批通过后自动通知抄送人</span
              >
            </div>
            <div
              class="approvers_box"
              v-if="ccs.length > 0"
              @click="dialog3 = true"
            >
              <div class="approvers_box_users" style="margin: 20px">
                <div v-for="user in ccs" :key="user">
                  {{ getEmployee(user) && getEmployee(user).name }}
                </div>
              </div>
            </div>
            <div
              v-else
              class="add_approver_button custom_button plain no_select"
              style="margin: 20px"
              @click="dialog3 = true"
            >
              点击此处添加抄送人
            </div>
          </div>
          <!-- 表单侧窗 -->
          <el-drawer
            title="审批员工"
            :visible.sync="dialog2"
            direction="rtl"
            ref="drawer"
            :modal="false"
            :size="650"
            @closed="drawer2Close"
          >
            <div class="drawer_content" v-if="selectedApprover !== null">
              <el-form>
                <el-form-item label="名称">
                  <el-input
                    v-model="approvers[selectedApprover].name"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="审批方式">
                  <el-select
                    v-model="approvers[selectedApprover].type"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in approverTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="审批员工"> </el-form-item>
                <el-transfer
                  :titles="['员工', '审批队列']"
                  filterable
                  filter-placeholder="请输入员工姓名"
                  v-model="approvers[selectedApprover].users"
                  :data="employeeList"
                  target-order="push"
                  :props="{
                    key: 'id',
                    label: 'name',
                  }"
                >
                </el-transfer>
                <div>tips: 暂不支持配置多人的审批轮次，请只选择一个员工。</div>
                <div
                  class="custom_button no_select"
                  style="margin-top: 20px"
                  @click="removeApprover"
                >
                  删除这轮审批
                </div>
              </el-form>
            </div>
          </el-drawer>
          <!-- 表单侧窗 -->
          <el-drawer
            title="抄送员工"
            :visible.sync="dialog3"
            direction="rtl"
            ref="drawer"
            :modal="false"
            :size="650"
          >
            <div class="drawer_content">
              <el-transfer
                :titles="['员工', '抄送队列']"
                filterable
                filter-placeholder="请输入员工姓名"
                v-model="ccs"
                :data="employeeList"
                target-order="push"
                :props="{
                  key: 'id',
                  label: 'name',
                }"
              >
              </el-transfer>
            </div>
          </el-drawer>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    const checkArray = (rule, value, callback) => {
      if (this.form2.apply_power == "2" && value.length < 1)
        return callback(new Error("请选择员工"));
      callback();
    };
    return {
      host: process.env.VUE_APP_BASE_API,
      activeName: "表单属性",
      panes: [
        {
          name: "表单属性",
          id: 1,
        },
        {
          name: "自定义内容",
          id: 2,
        },
        {
          name: "自定义流程",
          id: 3,
        },
      ],
      cate_options: [],
      selectedItem: null,
      selectedApprover: null,
      dialog: false,
      dialog2: false,
      dialog3: false,
      approvers: [], // 审批轮次列表
      employeeList: [], // 员工选择列表
      allEmployeeList: [], // 全部员工
      approverTypeOptions: [
        {
          label: "依次审批（按先后顺序依次审批）",
          value: 1,
        },
        {
          label: "会签审批（需所有人同意）",
          value: 2,
        },
        {
          label: "或签审批（1人同意即可）",
          value: 3,
        },
      ],
      ccs: [],
      form_items: [],

      form: {
        title: "",
        cover_img: "",
        cate_id: "",
        desc: "",
        sort_num: 0,
        apply_power: 2,
      },
      rules: {
        title: [
          {
            required: true,
            message: "请填写名称",
            trigger: "blur",
          },
        ],
        cover_img: [
          {
            required: true,
            message: "请上传图标",
            trigger: "change",
          },
        ],
        cate_id: [
          {
            required: true,
            message: "请选择分类",
            trigger: "change",
          },
        ],
        sort_num: [
          {
            required: true,
            message: "请填写排序",
            trigger: "blur",
          },
        ],
      },
      form2: {
        apply_power: "1",
        ids: "",
      },
      rules2: {
        ids: [
          {
            required: true,
            validator: checkArray,
            trigger: "change",
          },
        ],
      },
      mideaOptions: [
        {
          label: "全部",
          value: "all",
        },
        {
          label: "视频",
          value: "video",
        },
        {
          label: "图片",
          value: "image",
        },
        {
          label: "文档",
          value: "file",
        },
      ],
      formItemList: [
        {
          label: "输入框",
          value: 1,
        },
        {
          label: "文本框",
          value: 2,
        },
        {
          label: "单选",
          value: 3,
        },
        {
          label: "多选",
          value: 4,
        },
        {
          label: "时间选择器",
          value: 10,
        },
        {
          label: "单文件上传",
          value: 5,
        },
        {
          label: "多文件上传",
          value: 6,
        },
      ],
    };
  },
  props: ["id", "tid"],
  created() {
    this.getApproverCateList({ page: 1, size: 1000 })
      .then((res) => {
        console.log(res);
        this.cate_options = res.data.data.list.map((item) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
      })
      .catch((e) => {
        console.error(e);
      });

    // 正式员工
    this.getEmployeeList({
      page: 1,
      size: 10000,
      status: 3,
    })
      .then((res) => {
        console.log(res);
        this.employeeList = res.data.data.list.map((item) => {
          return {
            name: item.real_name,
            id: item.id,
          };
        });
      })
      .catch((e) => {
        console.error(e);
      });

    // 所有员工
    this.getEmployeeList({
      page: 1,
      size: 10000,
    })
      .then((res) => {
        console.log(res);
        this.allEmployeeList = res.data.data.list.map((item) => {
          return {
            name: item.real_name,
            id: item.id,
          };
        });
      })
      .catch((e) => {
        console.error(e);
      });

    if (this.id) {
      this.getApproverDetail({ id: this.id })
        .then((res) => {
          res = res.data.data.detail;
          console.log("data11111", res);
          this.form_items = res;
          this.form.id = this.id;
          this.form.title = res.title;
          this.form.cover_img = res.cover_img;
          this.form.cate_id = res.cate_id;
          this.form.desc = res.desc;
          this.form.sort_num = res.sort_num;
          this.form2.apply_power = String(res.apply_power);
          this.form.apply_power = String(res.apply_power);
          this.form2.ids = res.apply_power_staff_ids
            .split(",")
            .map((item) => Number(item));

          res.auditformTplItemList.forEach((item, index) => {
            this.$set(this.form_items, index, {
              type: item.type,
              title: item.title,
              tips: item.tips,
              default_val: item.default_val.match(",")
                ? item.default_val.split(",")
                : item.default_val,
              options: item.params_arr.options.map((item) => item.title),
              params: {
                type: item.params_arr.type,
              },
            });
          });

          res.auditformFlowList.forEach((item, index) => {
            this.$set(this.approvers, index, {
              name: item.title,
              type: item.type,
              users: item.auditerStaffList.map((employee) => employee.id),
            });
          });

          this.ccs = res.auditformPowerList.map((item) => item.staff_id);
        })
        .catch((e) => {
          console.error(e);
        });
    }

    // 加载模板
    if (this.tid) {
      let loading = this.$loading();
      this.getApprovalTemplateDetail({ id: this.tid })
        .then((res) => {
          res = res.data.data.detail;
          console.log(res);
          this.form.title = res.title;
          this.form.cover_img = res.cover_img;
          this.form.desc = res.desc;
          this.form.sort_num = res.sort_num;

          res.auditformTplItemList.forEach((item, index) => {
            this.$set(this.form_items, index, {
              type: item.type,
              title: item.title,
              tips: item.tips,
              default_val: item.default_val.match(",")
                ? item.default_val.split(",")
                : item.default_val,
              options: item.params_arr.options.map((item) => item.title),
              params: {
                type: item.params_arr.type,
              },
            });
          });

          loading.close();
        })
        .catch((e) => {
          loading.close();
          console.error(e);
          this.$message.error(e.msg);
        });
    }
  },
  computed: {
    getEmployee() {
      return (id) => {
        return this.employeeList.find((item) => item.id == id);
      };
    },
  },
  methods: {
    ...mapActions(["uploadFile"]),
    ...mapActions("attendance", [
      "getApproverCateList",
      "getApproverDetail",
      "postAddApprover",
      "postEditApprover",
      "getApprovalTemplateDetail",
    ]),
    ...mapActions("employee", ["getEmployeeList"]),

    handleClick(tab, event) {
      console.log(tab, event);
    },

    /**
     * 选择图片
     */
    chooseImage() {
      this.$refs.fileInput.click();
    },

    /**
     * 上传图片
     */
    uploadImage(file) {
      console.log(file, "上传");
      this.uploadFile(file.target.files[0])
        .then((res) => {
          console.log(res);
          this.form.cover_img = res.data.data.full_link;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    onItemSelected(index) {
      console.log(index);
      this.selectedItem = index;
      this.dialog = true;
    },

    drawerClose() {
      this.selectedItem = null;
    },

    addOption(item) {
      this.$set(item.options, item.options.length, "");
      item.default_val = [];
    },

    removeOption(item, index) {
      let arr = item.options;
      arr.splice(index, 1);
      item.options = arr;
      item.default_val = [];
    },

    addFormItem() {
      this.$set(this.form_items, this.form_items.length, {
        type: 1,
        title: "字段名",
        tips: "输入提示",
        default_val: "",
        options: [""],
        params: {
          type: "all",
        },
      });
    },

    removeFormItem() {
      let arr = this.form_items;
      arr.splice(this.selectedItem, 1);
      this.selectedItem = null;
      this.form_items = arr;
    },

    onSubmit() {
      console.log(this.form);
      let _this = this;
      // 第一页表单校验
      _this.$refs.form.validate((valid) => {
        if (!valid) {
          this.activeName = "表单属性";
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
        // 第二页表单校验
        if (_this.form_items.length > 0) {
          let auditform_tpl_item = [];
          _this.form_items.forEach((item) => {
            let temp = {
              title: item.title,
              type: item.type,
              tips: item.tips,
              // 默认值可能是数组
              default_val:
                item.default_val.length == 0
                  ? ""
                  : Array.isArray(item.default_val)
                  ? item.default_val.join(",")
                  : item.default_val,
            };
            if (item.params) temp.params = item.params;
            if (item.options) {
              temp.params.options = item.options.map((option) => {
                return {
                  title: option,
                };
              });
            }
            auditform_tpl_item.push(temp);
          });
          _this.form.auditform_tpl_item = JSON.stringify(auditform_tpl_item);
        } else {
          console.log("this.form_items.length", _this.form_items.length);
          this.activeName = "自定义内容";
          this.$notify.error({
            title: "提交失败",
            message: "请至少配置一个表单字段",
          });
          return false;
        }

        // 第三页
        // 头表单
        this.$refs.form2.validate((valid) => {
          if (!valid) {
            this.activeName = "自定义流程";
            this.$notify.error({
              title: "提交失败",
              message: "请填写完整表单",
            });
            return;
          }
          this.form.apply_power = this.form2.apply_power;
          if (this.form.apply_power == "2")
            this.form.apply_power_staff_ids = this.form2.ids.join(",");
          // 审批
          if (this.approvers.length > 0) {
            let auditform_flow = [];
            for (let item of this.approvers) {
              if (item.users && item.users.length > 0) {
                // 限制只能保存一人的队列
                if (item.users.length > 1) {
                  this.activeName = "自定义流程";
                  this.$notify.error({
                    title: "提交失败",
                    message: "暂不支持配置多人的审批轮次",
                  });
                  return;
                }

                auditform_flow.push({
                  title: item.name,
                  type: item.type,
                  auditer_staffids: item.users.join(","),
                });
              } else {
                this.activeName = "自定义流程";
                this.$notify.error({
                  title: "提交失败",
                  message: "配置了无审批人员的审批轮次",
                });
                return;
              }
            }
            this.form.auditform_flow = JSON.stringify(auditform_flow);
          } else {
            this.activeName = "自定义流程";
            this.$notify.error({
              title: "提交失败",
              message: "请至少配置一个审批轮次",
            });
            return false;
          }
          // 抄送
          let auditform_power = [];
          if (this.ccs.length > 0) {
            this.ccs.forEach((item) => {
              auditform_power.push({
                type: 2,
                staff_id: item,
              });
            });
          }
          this.form.auditform_power = JSON.stringify(auditform_power);

          console.log("最终表单", this.form);
          let loading = this.$loading();
          this.dialogVisible3 = false;
          if (this.id) {
            this.postEditApprover(this.form)
              .then((res) => {
                console.log(res);
                loading.close();
                this.$message.success(res.data.msg);
                this.$router.push({ name: "ApprovalForms" });
              })
              .catch((e) => {
                console.log(e);
                loading.close();
                this.$message.error(e.msg);
              });
          } else {
            this.postAddApprover(this.form)
              .then((res) => {
                console.log(res);
                loading.close();
                this.$message.success(res.data.msg);
                this.$router.push({ name: "ApprovalForms" });
              })
              .catch((e) => {
                console.log(e);
                loading.close();
                this.$message.error(e.msg);
              });
          }
        });
      });
    },

    onApproverSelected(index) {
      console.log(index);
      this.selectedApprover = index;
      this.dialog2 = true;
    },

    drawer2Close() {
      this.selectedApprover = null;
    },

    addApprover() {
      this.$set(this.approvers, this.approvers.length, {
        name: "审批",
        type: 1,
        users: [],
      });
      this.selectedApprover = this.approvers.length - 1;
      this.dialog2 = true;
    },

    removeApprover() {
      let arr = this.approvers;
      arr.splice(this.selectedApprover, 1);
      this.selectedApprover = null;
      this.approvers = arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.el-tabs {
  ::v-deep .el-tabs__header {
    margin: 0;
  }

  ::v-deep .el-tabs__item {
    font-size: 16px;
    color: #495060;
    height: 45px;

    &.is-active {
      color: #4db9d5;
    }
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #4db9d5;
  }
}

.el-tab-pane {
  padding: 20px 0;
}

.save_button {
  position: absolute;
  right: 20px;
  z-index: 1;
}

.el-form-item,
.el-select {
  max-width: 500px;
}

.add_photo {
  width: 100px;
  height: 100px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  color: #dcdfe6;
}

// 手机
.phone_box {
  position: relative;

  .phone_screen {
    position: absolute;
    background-color: #efefef;
    width: 307px;
    height: 544px;
    top: 124px;
    left: 245px;
    overflow-x: auto;

    .active {
      box-shadow: rgba($color: #4db9d5, $alpha: 0.3) 0px 0px 5px;
    }

    .input,
    .file,
    .add_item {
      margin: 10px 0;
      padding: 10px;
      background-color: #fff;
    }

    // 单行文本输入
    .input {
      display: flex;
      align-items: center;

      > div:nth-child(1) {
        width: 80px;
        font-size: 12px;
        color: #333;
        flex-shrink: 0;
      }

      > div:nth-child(2) {
        font-size: 12px;
        color: #b2b2b2;
        margin-left: 10px;

        &.default {
          color: #333;
        }
      }

      textarea {
        width: 100%;
        height: 50px;
      }

      > i {
        color: #333;
        font-size: 14px;
        position: absolute;
        right: 10px;
      }
    }

    .file {
      .file_title {
        display: flex;
        align-items: center;

        > div:nth-child(1) {
          width: 80px;
          font-size: 12px;
          color: #333;
          flex-shrink: 0;
        }

        > div:nth-child(2) {
          font-size: 12px;
          color: #b2b2b2;
          margin-left: 10px;
        }
      }

      .file_items {
        margin-top: 10px;
        margin-left: 90px;
      }
    }

    .add_item {
      text-align: center;
      color: #4db9d5;
    }
  }
}

.drawer_content {
  padding: 0 30px;

  .pickerInput {
    display: flex;
    align-items: center;
    width: 100%;

    .el-icon-circle-plus {
      font-size: 32px;
      color: #4db9d5;
    }

    .el-icon-remove {
      font-size: 32px;
      color: #409eff;
      margin: 0 5px;
    }
  }
}

.approve_title {
  font-size: 16px;
  color: #333;
  margin-left: 20px;

  > span {
    margin-left: 20px;
    font-size: 12px;
    color: #999;
  }
}

.approvers_boxs {
  margin-top: 30px;

  .approvers_box {
    cursor: pointer;
    width: max-content;
  }

  .approvers_box_users {
    display: flex;
    margin: 25px 0;
    cursor: pointer;

    > div {
      color: #333;
      border: 1px solid #ccc;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      text-align: center;
      position: relative;
      font-size: 14px;
      margin: 0 10px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.order {
        margin: 0 20px;

        &:not(:last-child)::after {
          content: ">";
          position: absolute;
          width: 30px;
          height: 30px;
          font-size: 20px;
          right: -35px;
        }
      }
    }
  }
}

.add_approver_button {
  width: 150px;
}

.el-select {
  width: 100%;
}
</style>
